<template>
  <div v-if="Number(product.mainProductPrices.retailprice) > 0 || selectedCountry.isocode2 == 'XX'">
  <div :class="`rsf-compare-product-card rsf-bundle-card rsf-card-interaction-animation bg-color-white flex flex-column h100 border-box pb2 ${isSelected ? 'card-selected' : ''}`"
    style="border-radius: 4px">
    <div :class="`flex justify-between mb1 gap2`">
      <div class="reefer-badge-wrapper">
        <div :class="`rsf-tank-badge h6 open-sans-bold ${showTag ? '' : 'op0 h1px col-12 overflow-hidden'} ${isDiscountedBundle ? 'rsf-discounted-bundle-badge' : 'rsf-build-your-own-badge'}`">{{isDiscountedBundle ?  $t('rsf_tank_card_tag_fully_equipped') : $t('rsf_tank_card_tag_build_your_own')}}</div>
      </div>
      <button class="i-btn pointer p0" @click="showModal = !showModal"><svg style="width: 24px" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="9.75" stroke="#909CA5" stroke-width="1.5" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M12 8.25C12.6904 8.25 13.25 7.69036 13.25 7C13.25 6.30964 12.6904 5.75 12 5.75C11.3096 5.75 10.75 6.30964 10.75 7C10.75 7.69036 11.3096 8.25 12 8.25ZM12 9.5C12.6041 9.5 13.108 9.92848 13.2246 10.4981C13.2413 10.5795 13.25 10.6637 13.25 10.75V17C13.25 17.6904 12.6904 18.25 12 18.25C11.3096 18.25 10.75 17.6904 10.75 17L10.75 12C10.0596 12 9.5 11.4404 9.5 10.75C9.5 10.0596 10.0596 9.5 10.75 9.5H12Z"
            fill="#909CA5" />
        </svg>
      </button>
    </div>
    <img @click="selectTank(product)" :class="`col-12 contain tank-card-img pointer ${imgLoaded ? 'display-tank-card-img' : ''} `" @load="imgLoaded = true" :src="imgSrc" />
    <div style="flex: 1;" @click="selectTank(product)" :class="`px1 pb3 pointer ${ selectedCountry.isocode2 == 'XX' ? '' : 'justify-between' } border-box flex flex-column`">
      <div>
        <h4 class="h4 helvetica-bold color-black center">{{ product.BundleName }}</h4>
        <p class="center h6 color-14 open-sans-regular">{{measurement == 'metric' ? product.mainProductInfo.LengthMetric + ' cm' : product.mainProductInfo.LengthImperial + '"' }} |
        {{measurement == 'metric' ? product.mainProductInfo.LiterMetric + ' L' : product.mainProductInfo.GallonMetric + ' gal'}}</p>
      </div>
      <div v-if="selectedCountry.isocode2 != 'XX'" :class="selectedCountry.isocode2 == 'XX' ? 'op0 pointer-events-none' : ''">
        <div class="relative" v-if="isDiscountedBundle">
          <p class="mt3 center open-sans-bold h4">{{ formatCurrency(product.mainProductPrices.currency, product.mainProductPrices.cumulateddiscountprice)}}</p>
          <p class="rsf-tank-card-price-reduced  color-6 h5 crossed center open-sans-regular">
            {{ formatCurrency(product.mainProductPrices.currency, product.mainProductPrices.cumulatedprice) }}</p>
        </div>
        <p v-if="!isDiscountedBundle" class="mt3 center open-sans-bold h4">{{ formatCurrency(product.mainProductPrices.currency, product.mainProductPrices.retailprice) }}</p>
      </div>
    </div>
  </div>
  <TankLightBox :measurement="measurement" :showModal="showModal" :closeModal="closeModal" :product="product" :selectedColor="selectedColor" :exitModal="exitModal"/>
</div>
</template>

<script>
import TankLightBox from './TankLightBox.vue'

export default {
  name: 'TankCard',
  props: {
    product: Object,
    selectedProducts: Array,
    toggleSelectedProduct: Function,
    selectTank: Function,
    isDiscountedBundle: Boolean,
    formatCurrency: Function,
    selectedColor: String,
    selectedCountry: Object,
    measurement: String,
    tallestCardHeight: Number,
    showTag: Boolean
  },
  components: {
    TankLightBox
  },
  data() {
    return {
      showModal: false,
      exitModal: false,
      imgLoaded: false,
      imgSrc: ''
    }
  },
  computed: {
    isSelected() {
      for (let i = 0; i < this.selectedProducts.length; i++) {
        if (this.selectedProducts[i].id == this.product.id) {
          return true
        }
      }
      return false
    },
  },
  mounted(){
    if(this.selectedColor == 'White'){
      if(this.product.colorfulListImages.whiteThumbnail){
        this.imgSrc = this.product.colorfulListImages.whiteThumbnail
      }else{
        this.imgSrc = this.product.colorfulListImages.whiteImage
      }
    }else if(this.selectedColor == 'Black'){
      if(this.product.colorfulListImages.blackThumbnail){
        this.imgSrc = this.product.colorfulListImages.blackThumbnail
      }else{
        this.imgSrc = this.product.colorfulListImages.blackImage
      }
    }else{
      this.imgSrc = this.product.colorfulListImages.whiteImage
    }
  },
  watch: {
    selectedColor(newValue){
      if(newValue == 'White'){
        if(this.product.colorfulListImages.whiteThumbnail){
          this.imgSrc = this.product.colorfulListImages.whiteThumbnail
        }else{
          this.imgSrc = this.product.colorfulListImages.whiteImage
        }
      }else if(newValue == 'Black'){
        if(this.product.colorfulListImages.blackThumbnail){
          this.imgSrc = this.product.colorfulListImages.blackThumbnail
        }else{
          this.imgSrc = this.product.colorfulListImages.blackImage
        }
      }else{
        this.imgSrc = this.product.colorfulListImages.whiteImage
      }
    }
  },
  methods: {
    closeModal() {
      this.exitModal = true,
      setTimeout(() => {
        this.showModal = !this.showModal
        this.exitModal = false;
      }, 300)
    },
  }
}
</script>

<style scoped>
  .rsf-tank-badge{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media(max-width: 500px){
    .rsf-tank-badge{
      max-width: 90%;
    }
  }
</style>
